(function($) {
    var $body = $('body'),
        $document = $(document),
        cssBreakPoints = {
            'xs': 480,
            'sm': 768,
            'md': 992,
            'lg': 1250
        };

    $(function(){
        //Comments slider
        var comm_responsive = {
                0: {
                    items: 1
                }
            };
        comm_responsive[cssBreakPoints.md] = {items:2};
        comm_responsive[cssBreakPoints.lg] = {items:3};
        $('.main-carousel').owlCarousel({
            loop: true,
            items: 3,
            dotsEach: true,
            nav: false,
            dots: true,
            autoplay: true,
            dotsSpeed: 300,
            autoplayHoverPause: true
        });
        var mobMenu = $('#mob-menu'),
            mobMenuButt = $('#mob-menu-btn');

        mobMenuButt.on('click', function() {
            mobMenuButt.toggleClass('active');
            mobMenu.toggleClass('opened');
            $document.on('click.mob-menu', function(e) {

                if ((!$(e.target).is(mobMenu.find('*'))) && (!$(e.target).is(mobMenuButt)) && (!$(e.target).is(mobMenuButt.find('*')))) {
                    mobMenu.removeClass('opened');
                    mobMenuButt.removeClass('active');
                    $document.off('click.mob-menu');
                }

            });
        });

        $('.gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            }
        });

        $('.video').magnificPopup({
            type: 'iframe'
        });

        $('.popup-trigger').magnificPopup({
            type: 'inline',
            midClick: true
        });

        $('.category-trigger').on('click', function() {
            var $this = $(this),
                $parent = $this.parent();

            if ($parent.hasClass('opened')) {
                $this.next().slideUp(200, function() {
                    $parent.removeClass('opened');
                });
            } else {
                $this.next().slideDown(200, function() {
                    $parent.addClass('opened');
                });
            }

        });
    });

    $(window).on('load', function() {

    });

})(jQuery);